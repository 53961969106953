import { Booking, Minor } from '@/types/api/serializers'
import Repository from './repository'

export type PatchMinorArgs = {
  minor: Partial<Minor> & { id: number } // id is required, all other properties are optional
  bookingCode: number
  propCode?: string
}

export type PostMinorArgs = {
  minor: Partial<Minor>
  bookingCode: string
  propCode?: string
}

export default {
  postMinor({
    minor,
    bookingCode,
    propCode,
  }: {
    minor: Partial<Minor>
    bookingCode: string
    propCode?: string
  }) {
    return Repository.post<Booking>(`/bookings/${bookingCode}/minors`, {
      minor,
      ...(propCode ? { propCode } : {}),
    })
  },
  patchMinor({
    minor,
    bookingCode,
    propCode,
  }: {
    minor: Partial<Minor>
    bookingCode: string
    propCode?: string
  }) {
    return Repository.patch<Minor>(
      `/bookings/${bookingCode}/minors/${minor.id}`,
      {
        minor,
        ...(propCode ? { propCode } : {}),
      }
    )
  },
}
