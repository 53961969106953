import CheckinsRepository, {
  ReassignGuestArgs,
} from '@/api/repositories/checkins'
import saveStateToStorage from '@/utils/saveStateToStorage'
import getSavedState from '@/utils/getSavedState'
import { Checkin, Guest } from '@/types/api/serializers'
import { PatchCheckinGuestArgs } from '@/api/repositories/checkins'
import {
  getters as propertyGetters,
  state as propertyState,
} from './properties'
import { getters as bookingGetters, state as bookingState } from './bookings'

export type CurrentStep = {
  number: number
  totalStepCount: number
  messageKey: string
  notFound?: boolean
}

export const state = {
  checkin: getSavedState('checkin'),
}

export const getters = {
  checkin(state): Checkin {
    return state.checkin
  },
  guest(state): Guest {
    return state.checkin?.guest
  },
  guestIsReturning(state, getters) {
    return getters.guest?.returning
  },
  guestIsJapanResident(state, getters) {
    return getters.guest?.country === 'JP'
  },
  guestIsJapanCitizenOrResident(state, getters) {
    return (
      getters.guest?.country === 'JP' || getters.guest?.nationality === 'JP'
    )
  },
  guestIsPrimary(state) {
    return !!state.checkin?.primary
  },
  guestExists(state, getters) {
    return !!getters.guest?.id
  },
  guestEmail(state, getters) {
    return getters.guest?.email
  },
  guestIsCheckedIn(state) {
    return !!state.checkin?.completedAt
  },
  guestIsPreCheckedIn(state, getters) {
    return getters.guest?.idPhotoKey && !getters.guestIsCheckedIn
  },
  checkinSteps(state): { name: string; messageKey: string }[] {
    if (!state.checkin) return []

    const booking = bookingGetters.booking(bookingState)
    const bookingHasOthers =
      (booking?.numAdult || 1) > 1 || (booking?.numChild || 0) > 0
    const isPrimary = state.checkin?.primary
    const isAtProperty = propertyGetters.atProperty(propertyState)

    const mugshotIsSkippable = propertyGetters.propertyIsMannedRightNow(
      propertyState,
      propertyGetters
    )

    const checkInFlows = {
      atProperty: [
        { name: 'GuestInfo', messageKey: 'pages.checkin.steps.guestInfo' },
        { name: 'ContactInfo', messageKey: 'pages.checkin.steps.contactInfo' },
        { name: 'TravelInfo', messageKey: 'pages.checkin.steps.travelInfo' },
        { name: 'IdInfo', messageKey: 'pages.checkin.steps.id' },
        {
          name: mugshotIsSkippable
            ? 'MugshotCaptureSkippable'
            : 'MugshotCapture',
          messageKey: 'pages.checkin.steps.mugshot',
        },
        {
          name: 'CheckinAgreement',
          messageKey: 'pages.checkin.steps.agreement',
        },
      ],
      notAtProperty: [
        { name: 'GuestInfo', messageKey: 'pages.checkin.steps.guestInfo' },
        { name: 'ContactInfo', messageKey: 'pages.checkin.steps.contactInfo' },
        { name: 'TravelInfo', messageKey: 'pages.checkin.steps.travelInfo' },
        { name: 'IdInfo', messageKey: 'pages.checkin.steps.id' },
        ...(isPrimary && bookingHasOthers
          ? [
            {
              name: 'OtherGuests',
              messageKey: 'pages.checkin.steps.otherGuests',
            },
          ]
          : []),
      ],
    }

    return isAtProperty ? checkInFlows.atProperty : checkInFlows.notAtProperty
  },
  currentStep(_, getters, rootState): CurrentStep {
    const defaultCheckinStep = {
      number: 0,
      messageKey: 'general.start',
      notFound: true,
    }

    const steps = getters.checkinSteps

    return {
      totalStepCount: steps.length,
      ...steps.reduce((acc, { name, messageKey }, idx) => {
        const foundCurrentRoute = name === rootState.route.name
        return foundCurrentRoute
          ? {
            number: idx,
            messageKey,
          }
          : acc
      }, defaultCheckinStep),
    }
  },
}

export const mutations = {
  SET_CHECKIN(state, checkin) {
    state.checkin = checkin
    saveStateToStorage('checkin', state.checkin)
  },

  RESET_CHECKIN(state) {
    state.checkin = null
    saveStateToStorage('checkin', state.checkin)
  },
}

export const actions = {
  async setCheckin({ commit }, checkin) {
    commit('SET_CHECKIN', checkin)
  },
  async createCheckin(
    { commit, dispatch, rootGetters },
    { email, bookingNo, primary, inviteUuid, dateOfBirth }
  ) {
    try {
      const response = await CheckinsRepository.postCheckin({
        email,
        bookingNo,
        propCode: rootGetters['properties/code'],
        primary,
        inviteUuid,
        dateOfBirth,
      })
      const newCheckin = response.data
      dispatch('bookings/addCheckin', { checkin: newCheckin }, { root: true })
      commit('SET_CHECKIN', newCheckin)
      return [newCheckin, null]
    } catch (e) {
      return [null, e]
    }
  },
  async updateCheckin({ commit, dispatch, rootGetters }, { checkin }) {
    const response = await CheckinsRepository.patchCheckin({
      checkin,
      propCode: rootGetters['properties/code'],
    })
    const updatedCheckin = response.data
    dispatch('bookings/addCheckin', { checkin: updatedCheckin }, { root: true })
    commit('SET_CHECKIN', updatedCheckin)
    return updatedCheckin
  },
  createOrUpdateCheckin({ dispatch }, { checkin, bookingNo }) {
    return checkin.checkinId
      ? dispatch('updateCheckin', { checkin })
      : dispatch('createCheckin', { checkin, bookingNo })
  },
  async updateCheckinGuest(
    { commit, rootGetters },
    { checkinId, guest }: Omit<PatchCheckinGuestArgs, 'propCode'>
  ): Promise<Checkin> {
    const response = await CheckinsRepository.patchGuest({
      checkinId,
      guest,
      propCode: rootGetters['properties/code'],
    })
    const updatedCheckin = response.data
    commit('SET_CHECKIN', updatedCheckin)
    return updatedCheckin
  },
  async reassignGuest(
    { state, commit },
    { email, dateOfBirth }: ReassignGuestArgs
  ): Promise<
    [Checkin, null] | [null, { type: string; message: string; metadata?: any }]
  > {
    try {
      const checkinId = getters.checkin(state).id
      const response = await CheckinsRepository.reassignGuest({
        checkinId,
        email,
        dateOfBirth,
      })
      const updatedCheckin = response.data
      commit('SET_CHECKIN', updatedCheckin)
      return [updatedCheckin, null]
    } catch (e) {
      return [null, e]
    }
  },
  resetCheckin({ commit }) {
    commit('RESET_CHECKIN')
  },
}
