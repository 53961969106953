// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.

import camelCase from 'lodash/camelCase'

const modulesCache = {}
type StoreData = { modules: { modules: Object; namespaced?: any } }
const storeData: StoreData = { modules: { modules: {} } }

// Allow us to dynamically require all Vuex module files.
// Include any .js and .ts files that are not this file or a unit test.
const getStoreModules = () =>
  import.meta.glob(
    [
      './**/*.js',
      './**/*.ts',
      '!./index.ts',
      '!./**/*.d.ts',
      '!./**/*.unit.js',
      '!./**/*.unit.ts',
    ],
    { eager: true }
  )

// For every Vuex module...
Object.entries(getStoreModules()).forEach(
  (moduleTuple: [string, NodeModule | unknown]) => {
    const [path, m] = moduleTuple
    if (!path || !m) return
    // Skip the module during hot reload if it refers to the
    // same module definition as the one we have cached.
    if (modulesCache[path] === m) return

    // Update the module cache, for efficient hot reloading.
    modulesCache[path] = m

    // Get the module path as an array.
    const modulePath: string[] = path
      // Remove the "./" from the beginning.
      .replace(/^\.\//, '')
      // Remove the file extension from the end.
      .replace(/\.\w+$/, '')
      // Split nested modules into an array path.
      .split(/\//)
      // camelCase all module namespaces and names.
      .map(s => camelCase(s))

    // Get the modules object for the current path.
    const { modules } = getNamespace(storeData, modulePath)

    // Add the module to our modules object.
    modules[modulePath.pop() || ''] = {
      // Modules are namespaced by default.
      namespaced: true,
      ...m,
    }

    // If the environment supports hot reloading...
    if (m.hot) {
      // Whenever any Vuex module is updated...
      import.meta.hot.accept(path, () => {
        // Update `storeData.modules` with the latest definitions.
        const storeModules = getStoreModules()
        // Trigger a hot update in the store.
        const parent = import.meta.glob('../index.ts', { eager: true })
        parent.default.hotUpdate({ modules: storeModules })
      })
    }
  }
)

// Helper Function: Recursively get the namespace of a Vuex module, even if nested.
function getNamespace(subtree: StoreData, path: string[]): StoreData {
  if (path.length === 1) return subtree

  const namespace = path.shift() || ''
  subtree.modules[namespace] = {
    modules: {},
    namespaced: true,
    ...subtree.modules[namespace],
  }
  return getNamespace(subtree.modules[namespace], path)
}

export const store = storeData.modules
