<template>
  <base-label
    :class="labelClass"
    :for="name"
    class="mb-4 text-sm font-semibold md:mb-6 md:text-base md:font-medium"
  >
    <span :class="{ 'inline-block w-1/3': oneLine }">{{ label }}</span>
    <span
      v-if="errorsPresentOnDateOrEmailFieldTypes"
      class="mb-2 ml-2 text-sm text-red-600 md:text-base"
    >
      {{ v$.$errors[0]?.$message }}
    </span>
    <input
      :type="type"
      :name="name"
      :value="modelValue"
      v-bind="$attrs"
      :placeholder="`${placeholderText}`"
      :autocomplete="autocomplete ? 'on' : 'off'"
      class="mt-2 h-[50px] bg-white text-sm font-normal focus:border-[1px] focus:border-black focus:outline-none disabled:bg-gray-100 disabled:text-gray-700 md:mt-4 md:text-base md:font-medium"
      :class="[
        {
          'border border-red-600 placeholder-red-600':
            !!v$.$errors.length && v$.$dirty,
          'border-[1px] border-lightgray': borderAlwaysOn,
          'inline w-2/3': oneLine,
          'block w-full': !oneLine,
        },
        inputClass,
      ]"
      @input="handleCustomInput(($event?.target as HTMLInputElement).value)"
    />
  </base-label>
</template>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<script setup lang="ts">
import useValidations, { ModelValue } from '@/utils/setup/useValidations'
import { computed } from 'vue'

const validationTypes = [
  'date',
  'email',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'url',
] as const

type ValidationType = (typeof validationTypes)[number]

const props = withDefaults(
  defineProps<{
    autocomplete?: boolean
    modelValue?: string | null
    label?: string
    labelClass?: string
    inputClass?: string
    required?: boolean
    placeholder?: string
    type?: ValidationType
    borderAlwaysOn?: boolean
    oneLine?: boolean
  }>(),
  {
    required: false,
    type: 'text',
    autocomplete: false,
    placeholder: '',
    modelValue: null,
    borderAlwaysOn: false,
    oneLine: false,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
}>()

const { handleCustomInput, v$ } = useValidations(props, emit)

// computed
const name = computed(() => props.label?.toLowerCase())
const placeholderText = computed(() => {
  return v$.value.$errors.length
    ? v$.value.$errors[0]?.$message
    : props.placeholder
})
const errorsPresentOnDateOrEmailFieldTypes = computed(() => {
  return (
    !!v$.value.$errors.length &&
    (props.type === 'date' || v$.value.$errors[0].$validator === 'email')
  )
})
</script>
