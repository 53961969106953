<template>
  <modal
    ref="verifyCheckinGuestAssignmentModal"
    class="h-11/12 overflow-y-hidden"
    @close="cleanup"
  >
    <div class="flex flex-col items-center justify-center gap-8">
      <p class="text-sm font-semibold md:text-xl">
        {{ $t('modals.verifyCheckinGuestAssignment.message') }}
      </p>
      <p
        class="text-sm md:text-base"
        v-html="
          $t('modals.verifyCheckinGuestAssignment.description', {
            email: `<span class='text-orange'> ${email}</span>`,
          })
        "
      ></p>
      <div class="w-full px-0 md:px-14">
        <base-input-text
          :v-model="guestName"
          :label="$t('modals.verifyCheckinGuestAssignment.name')"
          disabled
          :one-line="!isMobile"
          border-always-on
          label-class="w-full text-base text-left"
          :placeholder="guestName"
          class="mb-4"
          data-cy="firstNameInput"
        />
        <birthday-fields
          v-model="dateOfBirth"
          :one-line="!isMobile"
          border-always-on
        />
        <p
          v-if="shouldShowWrongDateMessage"
          class="mt-4 text-left text-sm font-medium text-Error md:text-base"
        >
          {{ $t('modals.verifyCheckinGuestAssignment.wrongDate') }}
        </p>
      </div>
      <base-button
        v-if="noUnsuccessfulAttempts"
        small
        full-width
        :disabled="v.$invalid || appLoading"
        @click="appLoading ? () => {} : submit()"
      >
        {{
          $t('modals.verifyCheckinGuestAssignment.topButtonText', { guestName })
        }}
      </base-button>
      <base-button
        v-else
        small
        full-width
        :disabled="v.$invalid || appLoading"
        @click="appLoading ? () => {} : submit()"
      >
        {{
          $t('modals.verifyCheckinGuestAssignment.afterFailureTopButtonText', {
            guestName,
          })
        }}
      </base-button>
      <base-button
        small
        full-width
        secondary
        data-cy="useDifferentEmailBtn"
        @click="cancel"
      >
        {{ $t('modals.verifyCheckinGuestAssignment.bottomButtonText') }}
      </base-button>
    </div>
  </modal>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import Modal from '@/components/modals/Modal.vue'
import { key } from '@/store'
import BirthdayFields from '../BirthdayFields.vue'
import { Booking, Checkin } from '@/types/api/serializers'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import isMobileDetector from '@/utils/isMobile'

const isMobile = computed(isMobileDetector)

const props = defineProps<{
  email: string | null
  booking: Booking
  primary: 'true' | 'false'
  inviteUuid?: string
  guestName: string
  afterModalClose: () => void
}>()
const verifyCheckinGuestAssignmentModal = ref<InstanceType<typeof Modal>>()

const { t } = useI18n()
const router = useRouter()
const store = useStore(key)
const v = useVuelidate()

const appLoading = computed(() => store.getters.appLoading)

const noUnsuccessfulAttempts = ref(true)

const dateOfBirth = ref('')
const shouldShowWrongDateMessage = ref(false)
watch(dateOfBirth, (newDob, oldDob) => {
  if (oldDob !== newDob) shouldShowWrongDateMessage.value = false
})

const show = () => {
  verifyCheckinGuestAssignmentModal?.value?.show()
}

const close = async () => {
  cleanup()
  verifyCheckinGuestAssignmentModal?.value?.close()
}

const cancel = async () => {
  close()
  props.afterModalClose()
}

const cleanup = async () => {
  dateOfBirth.value = ''
  store.dispatch('resetCustomAlert')
}

const submit = async () => {
  const formValid = await v.value.$validate()

  if (formValid && props.email) {
    // try to add a checkin to the booking
    const [checkinResponse, error] = await createCheckin({
      email: props.email,
      bookingNo: props.booking.code || '',
      primary: props.primary,
      inviteUuid: props.inviteUuid,
      dateOfBirth: dateOfBirth.value,
    })

    if (error || !checkinResponse) {
      noUnsuccessfulAttempts.value = false
      const defaultErrorHandler = () => {
        close()
        const [title, ...messages] = error.messages
        store.dispatch('setCustomAlert', {
          component: 'default',
          props: { title, messages },
        })
      }

      const handleErrorsByType =
        {
          IncorrectDateOfBirthWhenAssigningGuest: () =>
            (shouldShowWrongDateMessage.value = true),
          default: defaultErrorHandler,
        }[error?.type || 'default'] || defaultErrorHandler

      return handleErrorsByType()
    }

    // Set the successfully updated checkin
    store.dispatch('checkins/setCheckin', checkinResponse)

    // try to pull the updated booking into store
    await store.dispatch('bookings/fetchBooking', {
      bookingNo: props.booking.code,
    })

    // close modal and always navigate to Guest Info page when assigning an existing guest
    close()
    router.replace({ name: 'GuestInfo' })
  }
}

const createCheckin = async ({
  email,
  bookingNo,
  primary,
  inviteUuid,
  dateOfBirth,
}: {
  email: string
  bookingNo: string
  primary: 'true' | 'false'
  inviteUuid?: string
  dateOfBirth?: string
}): Promise<
  | [Checkin, null]
  | [
      null,
      { type: string; message: string; messages: string[]; metadata?: any },
    ]
> => {
  return await store.dispatch('checkins/createCheckin', {
    email,
    bookingNo,
    primary,
    inviteUuid,
    dateOfBirth,
  })
}

onMounted(() => {
  show()
})

onUnmounted(() => {
  close()
})

defineExpose({ show, close })
</script>
